/* Team section styles */
.team-container {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
  margin-top: 30px;
  align-items: stretch;
}

.team-segregation {
  padding-top: 2rem;
  margin-bottom: 40px;
  text-align: center;
}

.team-subheadline {
  display: inline-block;
  position: relative;
  margin-bottom: 30px;
  font-size: 1.8rem;
  color: #000 !important;
  padding: 0 10px 10px;
  font-weight: 500;
}

.team-subheadline:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 80px;
  height: 3px;
  background: var(--link-blue);
  transform: translateX(-50%);
  border-radius: 2px;
}

.team-member {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 215px;
  padding: 20px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  height: 100%;
}

.team-member:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: linear-gradient(90deg, var(--link-blue), #4dabf7);
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.4s ease;
}

.team-member:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 102, 153, 0.15);
}

.team-member:hover:before {
  transform: scaleX(1);
}

.team-photo-container {
  position: relative;
  margin-bottom: 20px;
  width: 120px;
  height: 120px;
  flex-shrink: 0;
}

.team-photo {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
  border: 4px solid white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.team-member:hover .team-photo {
  transform: scale(1.05);
  box-shadow: 0 8px 20px rgba(0, 102, 153, 0.2);
}

.team-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex-grow: 1;
  justify-content: space-between;
}

.team-name {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0 0 15px 0;
  color: var(--link-blue);
  text-align: center;
  min-height: 2.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.team-role {
  font-size: 0.9rem;
  color: #555;
  margin: 0;
  padding: 4px 12px;
  background-color: rgba(0, 102, 153, 0.1);
  border-radius: 20px;
  transition: all 0.3s ease;
}

.team-member:hover .team-role {
  background-color: rgba(0, 102, 153, 0.2);
}

/* Timeline section styles */
.timeline {
  border-left: 2px solid var(--link-blue);
  margin: 2rem auto;
  padding-left: 1.5rem;
  max-width: 1150px;
  position: relative;
}

.timeline-item {
  position: relative;
  margin-bottom: 1.5rem;
  padding-left: 1rem;
  transition: transform 0.3s ease-in-out;
  opacity: 0;
  animation: fadeInUp 0.6s ease forwards;
  animation-delay: calc(var(--item-index, 0) * 0.2s);
}

.timeline-item:hover {
  transform: translateY(-5px);
}

.timeline-date {
  font-weight: bold;
  margin-bottom: 0.3rem;
  color: #000;
  display: inline-block;
  background-color: rgba(0, 102, 153, 0.1);
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.timeline-item:hover .timeline-date {
  background-color: rgba(0, 102, 153, 0.2);
}

.timeline-description {
  margin: 0;
  font-size: 1rem;
  line-height: 1.4;
  padding: 0.5rem;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 5px;
  transition: all 0.3s ease;
  border-left: 3px solid transparent;
}

.timeline-item:hover .timeline-description {
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  border-left: 3px solid var(--link-blue);
}

.timeline-column {
  width: 50%;
  position: relative;
  padding: 0 20px;
  transition: all 0.5s ease;
  min-height: auto;
  height: auto;
}

.timeline-column .timeline-item {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-bottom: 3rem;
  position: absolute;
  width: calc(100% - 60px);
}

/* Links in timeline */
.timeline-description a {
  font-weight: 500;
  position: relative;
  text-decoration: none !important;
  transition: all 0.3s ease;
  display: inline-block;
}

.timeline-description a:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: var(--link-blue);
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease;
}

.timeline-description a:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* New zigzag timeline styles for larger viewports *
/* Acknowledgements section styles */
.section-inner.acknowledgements {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-top: 20px;
  padding-bottom: 30px;
}

.acknowledgements a {
  padding: 1rem;
  box-sizing: border-box;
  text-align: center;
  background: #fff;
  border-radius: 8px;
  transition: all 0.3s ease;
  flex: 1 1 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.acknowledgement-logo {
  width: 130px;
  height: 130px;
  object-fit: contain;
  margin: 10px;
  transition: all 0.3s ease;
  filter: grayscale(100%);
  transform: scale(0.95);
}

.acknowledgements a:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.acknowledgements a:hover .acknowledgement-logo {
  filter: grayscale(0%);
  transform: scale(1);
}

.timeline-bold {
  font-weight: 700;
  color: #000;
}

/* Hero section styles */
.hero-section {
  position: relative;
  background: linear-gradient(135deg, var(--link-blue) 0%, #006699 100%);
  border-radius: 0;
  padding: 0;
  box-shadow: 0 15px 30px rgba(0, 102, 153, 0.2);
  overflow: hidden;
  min-height: 450px;
  color: white;
  display: flex;
  flex-direction: column;
  margin-left: calc(-50vw + 50%);
  margin-right: 0;
  left: 0;
  right: 0;
  align-self: center;
  box-sizing: border-box;
  margin-top: -70px;
  padding-top: 70px;
}

.hero-bg-pattern {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='white' fill-opacity='0.1' fill-rule='evenodd'/%3E%3C/svg%3E");
  opacity: 0.7;
  z-index: 0;
}

.hero-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6rem 2rem;
  position: relative;
  z-index: 1;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.hero-title {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  text-align: center;
  animation: fadeInDown 0.8s ease;
  background: linear-gradient(45deg, #ffffff, #e6f7ff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.hero-description {
  font-size: 1.3rem;
  max-width: 900px;
  text-align: center;
  margin-bottom: 2rem;
  line-height: 1.6;
  animation: fadeInUp 0.8s ease;
  animation-delay: 0.2s;
  animation-fill-mode: both;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.hero-buttons {
  display: flex;
  gap: 1.5rem;
  margin-top: 2rem;
  animation: fadeInUp 0.8s ease;
  animation-delay: 0.4s;
  animation-fill-mode: both;
  flex-wrap: wrap;
  justify-content: center;
}

.hero-button {
  padding: 1rem 2rem;
  background-color: white;
  color: var(--link-blue);
  border-radius: 30px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 1.1rem;
}

.hero-button:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
  background-color: rgba(255, 255, 255, 0.95);
}

.hero-button-icon {
  margin-right: 8px;
}

.hero-stats {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;
  gap: 2rem;
  flex-wrap: wrap;
  animation: fadeInUp 0.8s ease;
  animation-delay: 0.6s;
  animation-fill-mode: both;
}

.hero-stat {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 1.2rem;
  flex: 1 1 150px;
  max-width: 200px;
  backdrop-filter: blur(5px);
  transition: all 0.3s ease;
}

.hero-stat:hover {
  background-color: rgba(255, 255, 255, 0.2);
  transform: translateY(-5px);
}

.hero-stat-number {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  background: linear-gradient(to right, #ffffff, #e6f7ff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-stat-label {
  font-size: 0.9rem;
  opacity: 0.9;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Introduction section styles */
.intro-section {
  position: relative;
  padding: 2rem;
  overflow: hidden;
  transition: all 0.5s ease;
}

.intro-section::before {
  content: none;
}

.intro-headline {
  position: relative;
  font-size: 2.2rem;
  margin-bottom: 1.5rem;
  text-align: center;
  z-index: 1;
}

.intro-headline::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  width: 80px;
  height: 3px;
  background: var(--link-blue);
  transform: translateX(-50%);
  border-radius: 2px;
}

.intro-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  position: relative;
  z-index: 1;
}

.intro-text {
  position: relative;
  background: white;
  border-radius: 10px;
  padding: 1.5rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  margin-bottom: 1.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.intro-text:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 102, 153, 0.15);
}

.intro-paragraph {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 0;
  position: relative;
  flex-grow: 1;
}

.intro-highlight {
  background: linear-gradient(to right, rgba(0, 102, 153, 0.1), transparent);
  border-radius: 4px;
  padding: 0.2rem 0.5rem;
  font-weight: 500;
  color: var(--link-blue);
}

.feature-category {
  margin-bottom: 1.5rem;
  background: white;
  border-radius: 10px;
  padding: 1.5rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.feature-category:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 102, 153, 0.15);
}

.feature-heading {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--link-blue);
  margin-bottom: 1rem;
}

.feature-icon {
  margin-right: 0.8rem;
  background: rgba(0, 102, 153, 0.1);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--link-blue);
}

.feature-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.feature-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.8rem;
  padding-left: 1rem;
  position: relative;
}

.feature-item::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0.65rem;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--link-blue);
}

.call-to-action {
  text-align: center;
  margin-top: 2rem;
  padding: 1.5rem;
  background: linear-gradient(135deg, rgba(0, 102, 153, 0.1) 0%, rgba(0, 102, 153, 0.05) 100%);
  border-radius: 10px;
  transition: all 0.3s ease;
}

.call-to-action:hover {
  background: linear-gradient(135deg, rgba(0, 102, 153, 0.15) 0%, rgba(0, 102, 153, 0.1) 100%);
}

.cta-text {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 0;
  color: #333;
}

.highlight-text {
  color: var(--link-blue);
  font-weight: 600;
}

.cta-contact {
  margin-top: 1.2rem;
  padding: 0.8rem 1.5rem;
  background-color: white;
  border-radius: 30px;
  display: inline-flex;
  align-items: center;
  box-shadow: 0 4px 12px rgba(0, 102, 153, 0.1);
  transition: all 0.3s ease;
  animation: fadeInUp 0.6s ease forwards;
  animation-delay: 0.2s;
  opacity: 0;
}

.cta-contact:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 15px rgba(0, 102, 153, 0.15);
}

.cta-email-label {
  font-weight: 600;
  color: #555;
  margin-right: 0.8rem;
  position: relative;
}

.cta-email-label::after {
  content: '';
  position: absolute;
  top: 50%;
  right: -0.4rem;
  transform: translateY(-50%);
  height: 15px;
  width: 1px;
  background-color: rgba(0, 102, 153, 0.3);
}

.cta-email {
  color: var(--link-blue);
  font-weight: 500;
  text-decoration: none !important;
  position: relative;
  transition: all 0.3s ease;
}

.cta-email::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--link-blue);
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease;
}

.cta-email:hover {
  color: var(--button-background-hover-blue);
  text-decoration: none !important;
}

.cta-email:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

/* Responsive adjustments */
@media (max-width: 767px) {
  .intro-section {
    padding: 1.5rem;
  }

  .intro-headline {
    font-size: 1.8rem;
  }

  .intro-paragraph {
    font-size: 1rem;
  }

  .hero-section {
    margin-left: calc(-50vw + 50%);
    min-height: 400px;
  }

  .hero-content {
    padding: 4rem 1.5rem;
  }

  .hero-title {
    font-size: 2.2rem;
  }

  .hero-description {
    font-size: 1.1rem;
  }

  .hero-stat {
    flex: 1 1 120px;
  }

  .hero-buttons {
    flex-direction: column;
    gap: 1rem;
  }

  /* Timeline mobile styles */
  .timeline-item::before {
    content: '';
    position: absolute;
    left: -12px;
    top: -5px;
    width: 16px;
    height: 16px;
    background-color: var(--link-blue);
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 0 0 0 3px rgba(0, 102, 153, 0.2);
    transition: all 0.3s ease;
  }

  .timeline-item:hover::before {
    background-color: var(--button-background-hover-blue);
    box-shadow: 0 0 0 5px rgba(0, 102, 153, 0.3);
    transform: scale(1.2);
  }

  .timeline-item::after {
    content: '';
    position: absolute;
    left: -25px;
    top: 0px;
    width: 26px;
    height: 2px;
    background-color: var(--link-blue);
    transition: all 0.3s ease;
  }

  .timeline-item:hover::after {
    background-color: var(--button-background-hover-blue);
  }

  /* Team section responsive */
  .team-container {
    gap: 20px;
  }

  .team-member {
    width: 180px;
    padding: 15px;
  }

  .team-photo-container {
    width: 100px;
    height: 100px;
    margin-bottom: 15px;
  }

  .team-photo {
    width: 100px;
    height: 100px;
  }

  .team-name {
    font-size: 1.1rem;
    min-height: 2.6rem;
    margin-bottom: 12px;
  }
}

@media (min-width: 576px) {
  /* Acknowledgements responsive */
  .acknowledgements a {
    flex: 1 1 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }
}

@media (min-width: 768px) {
  /* Timeline desktop styles */
  .timeline {
    display: flex;
    border-left: none;
    padding-left: 0;
    margin-top: 3rem;
    margin-bottom: 3rem;
    position: relative;
    min-height: auto;
    height: auto;
  }

  .timeline::after {
    content: '';
    position: absolute;
    top: 8px;
    left: calc(50% - 1px);
    width: 2px;
    height: calc(100% - 100px);
    background: var(--link-blue);
    z-index: 1;
  }

  .timeline-column:first-child {
    padding-right: 40px;
  }

  .timeline-column:last-child {
    padding-left: 40px;
  }

  .timeline-column:first-child .timeline-item {
    text-align: right;
    padding-left: 0;
    padding-right: 2rem;
  }

  .timeline-column:first-child .timeline-item:hover::before {
    background-color: var(--button-background-hover-blue);
    box-shadow: 0 0 0 5px rgba(0, 102, 153, 0.3);
    transform: scale(1.2);
  }

  .timeline-column:first-child .timeline-date,
  .timeline-column:first-child .timeline-description {
    padding-right: 1rem;
    position: relative;
  }

  .timeline-column:first-child .timeline-description {
    border-left: none;
    border-right: 3px solid transparent;
  }

  .timeline-column:first-child .timeline-item:hover .timeline-description {
    border-right: 3px solid var(--link-blue);
    border-left: none;
  }

  .timeline-column:last-child .timeline-item {
    text-align: left;
    padding-right: 0;
    padding-left: 2rem;
    margin-top: 65px;
  }

  .timeline-column:last-child .timeline-item:hover::before {
    background-color: var(--button-background-hover-blue);
    box-shadow: 0 0 0 5px rgba(0, 102, 153, 0.3);
    transform: scale(1.2);
  }

  .timeline-column:last-child .timeline-date,
  .timeline-column:last-child .timeline-description {
    padding-left: 1rem;
    position: relative;
  }

  .timeline-column:first-child .timeline-item::before {
    content: '';
    position: absolute;
    top: 2px;
    right: -9px;
    width: 16px;
    height: 16px;
    background-color: var(--link-blue);
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 0 0 0 3px rgba(0, 102, 153, 0.2);
    transition: all 0.3s ease;
    z-index: 2;
  }

  .timeline-column:first-child .timeline-item::after {
    content: '';
    position: absolute;
    top: 8px;
    right: -41px;
    width: 40px;
    height: 2px;
    background-color: var(--link-blue);
    z-index: 1;
  }

  .timeline-column:last-child .timeline-item::before {
    content: '';
    position: absolute;
    top: 2px;
    left: -9px;
    width: 16px;
    height: 16px;
    background-color: var(--link-blue);
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 0 0 0 3px rgba(0, 102, 153, 0.2);
    transition: all 0.3s ease;
    z-index: 2;
  }

  .timeline-column:last-child .timeline-item::after {
    content: '';
    position: absolute;
    top: 8px;
    left: -41px;
    width: 40px;
    height: 2px;
    background-color: var(--link-blue);
    z-index: 1;
  }

  /* Intro grid responsive */
  .intro-grid {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    row-gap: 3rem;
  }

  .intro-grid .feature-category {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .intro-grid .feature-list {
    flex-grow: 1;
  }

  /* Acknowledgements responsive */
  .acknowledgements a {
    flex: 1 1 calc(33.333% - 20px);
    max-width: calc(33.333% - 20px);
  }
}

@media (min-width: 1200px) {
  /* Acknowledgements desktop */
  .section-inner.acknowledgements {
    flex-wrap: nowrap;
    align-items: center;
  }

  .acknowledgements a {
    flex: 1;
    max-width: none;
  }
}

/* Navigation Header Styles */
.page-header-about {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.header-scrolled {
  padding: 0.6rem 2rem;
  box-shadow: 0 4px 20px rgba(0, 102, 153, 0.1);
  background: rgba(255, 255, 255, 0.98);
}

.page-nav {
  display: flex;
  align-items: center;
}

.nav-items {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 1rem;
}

.nav-items li {
  position: relative;
}

.nav-items li button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  padding: 0.6rem 1.2rem;
  font-size: 1rem;
  font-weight: 500;
  color: #333;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.nav-items li button:hover {
  background-color: rgba(0, 102, 153, 0.1);
  color: var(--link-blue);
}

.nav-items li.active button {
  background-color: rgba(0, 102, 153, 0.15);
  color: var(--link-blue);
  font-weight: 600;
}

.mobile-menu-toggle {
  display: none;
  background: none;
  border: none;
  color: var(--link-blue);
  font-size: 2rem;
  cursor: pointer;
  padding: 0.3rem;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.mobile-menu-toggle:hover {
  background-color: rgba(0, 102, 153, 0.1);
}

/* Mobile Navigation */
@media (max-width: 768px) {
  .page-header-about {
    padding: 1rem;
  }

  .header-scrolled {
    padding: 0.6rem 1rem;
  }

  .mobile-menu-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1002;
  }

  .page-nav {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateX(100%);
    transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
    z-index: 1001;
  }

  .page-nav.nav-open {
    transform: translateX(0);
  }

  .nav-items {
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
  }

  .nav-items li {
    width: 100%;
    text-align: center;
  }

  .nav-items li button {
    justify-content: center;
    width: 80%;
    margin: 0 auto;
    padding: 1rem;
    font-size: 1.2rem;
  }
}
