.sidebar {
  width: 280px;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  transition:
    width 0.3s ease,
    padding 0.3s ease;
  overflow-y: hidden;
}

.sidebar-title {
  padding: 0px 15px;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.sidebar.collapsed {
  width: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition:
    width 0.3s ease,
    padding 0.3s ease;
  overflow-y: hidden;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5%;
}

.mobile-toggle {
  position: absolute !important;
  top: 20px;
  left: 10px;
  z-index: 2100;
}

.buttons-container {
  position: relative;
  display: flex;
  gap: 0;
}

.sidebar-button-color {
  color: #0e0e0e !important;
}

.sidebar-icons svg {
  color: var(--button-background-blue);
}

.custom-sidebar-button {
  background: none;
  border: none;
  box-shadow: none;
  width: 50%;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  text-transform: none;
}

.custom-sidebar-button:hover {
  background-color: transparent;
}

.custom-sidebar-button .sidebar-icon {
  margin-bottom: 4px;
  margin-right: 5px;
  font-size: 26px;
  color: var(--button-background-blue);
}

.custom-sidebar-button .button-label {
  font-size: 14px;
  color: inherit;
  color: #0e0e0e;
  text-transform: none;
}

.underline-indicator {
  position: absolute;
  bottom: 0;
  width: 50%;
  height: 3px;
  background-color: #0e0e0e;
  transition: left 0.3s ease;
}

.side-by-side {
  flex: 1;
}

.sidebar-content {
  flex-grow: 1;
  overflow-y: auto;
}

.collapsed-icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.sidebar-expanded-content .sidebar-start-conversation button,
.sidebar-expanded-content .buttons-container button {
  display: block;
  padding: 15px 10px;
}

.collapsed-icons button svg {
  fill: var(--button-background-blue);
  font-size: 28px;
}

.sidebar-button-color svg {
  fill: var(--button-background-blue);
  font-size: 28px;
}

.collapsed-icons button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-sidebar-button.active:not(.start-conversation-button) {
  background-color: #f1f1f1;
}

.bookmark-item:hover {
  cursor: pointer;
}

.start-conversation-button {
  width: 100%;
  padding-top: 1rem !important;
}

.start-conversation-button .sidebar-icon {
  font-size: 26px;
}

.custom-sidebar-button:hover {
  background-color: transparent;
}

/* Add this new rule at the end of your file */
.sidebar:not(.collapsed) .custom-sidebar-button:hover {
  background-color: rgba(0, 0, 0, 0.08);
}
.bookmarks-list {
  font-size: 14px;
}

.bookmark-item {
  font-style: italic;
}

.MuiListItemText-primary {
  font-size: 14px !important;
}

.sidebar-list {
  padding-top: 0px;
}

/* Enhanced list item styles */
.sidebar-list .MuiListItem-root {
  transition: all 0.3s ease;
  margin-bottom: 8px;
  border-radius: 8px;
  overflow: hidden;
}

.dialogue-item,
.bookmark-item {
  position: relative;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  border-radius: 8px;
  padding: 10px 15px !important;
  overflow: hidden;
  background-color: transparent;
  transform: translateX(0);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02);
}

.dialogue-item::before,
.bookmark-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background-color: var(--link-blue);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.dialogue-item:hover,
.bookmark-item:hover {
  background-color: rgba(0, 102, 153, 0.05) !important;
  transform: translateX(3px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

.dialogue-item:hover::before,
.bookmark-item:hover::before {
  opacity: 1;
}

.MuiListItemText-primary {
  font-size: 14px !important;
  font-weight: 500;
  position: relative;
  transition: color 0.3s ease;
  color: #333;
}

.dialogue-item:hover .MuiListItemText-primary,
.bookmark-item:hover .MuiListItemText-primary {
  color: var(--link-blue);
}

.bookmark-item {
  font-style: italic;
}

.sidebar-icons .MuiIconButton-root {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  background-color: transparent;
  color: #555;
}

.sidebar-icons .MuiIconButton-root:hover {
  background-color: rgba(0, 102, 153, 0.1);
  color: var(--link-blue);
  transform: scale(1.05);
}

.bookmarks-list button:hover {
  background-color: rgba(0, 102, 153, 0.05) !important;
}

.sidebar-expanded-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* Fix the top portions so they don't shrink */
.sidebar-start-conversation,
.buttons-container {
  flex: 0 0 auto;
}

/* Make only the list scrollable */
.sidebar-content {
  flex: 1 1 auto;
  overflow-y: auto;
}

.sidebar-default-content {
  padding: 1.5rem !important;
  text-align: center;
  color: #666;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  margin: 1rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.03);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  position: relative;
  overflow: hidden;
  transform: translateY(0);
}

.sidebar-default-content::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(90deg, transparent, var(--link-blue), transparent);
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.sidebar-default-content:hover {
  box-shadow: 0 5px 15px rgba(0, 102, 153, 0.1);
  transform: translateY(-2px);
}

.sidebar-default-content:hover::before {
  opacity: 1;
}

.sidebar-toggle-button svg {
  fill: black !important;
}

.sidebar-expanded-content {
  display: flex;
  flex-direction: column;
  height: 95%;
}

.sidebar-scrollable-content {
  flex: 1 1 auto;
  overflow-y: auto;
}

.sidebar-bottom-row {
  flex: 0 0 auto;
  padding: 15px 10px;
  border-top: 1px solid rgba(0, 102, 153, 0.1);
  background: linear-gradient(135deg, rgba(0, 102, 153, 0.05) 0%, rgba(0, 102, 153, 0.02) 100%);
  position: relative;
  overflow: hidden;
}

.sidebar-bottom-row::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(90deg, transparent 0%, rgba(0, 102, 153, 0.2) 50%, transparent 100%);
}

.footer-links {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 8px;
  flex-wrap: wrap;
}

.sidebar-link {
  color: #666 !important;
  text-decoration: none !important;
  font-size: 0.9rem;
  transition: all 0.3s ease;
  position: relative;
}

.sidebar-link:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--link-blue);
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease;
}

.sidebar-link:hover {
  color: var(--link-blue) !important;
  text-decoration: none !important;
}

.sidebar-link:hover:after {
  transform: scaleX(1);
  transform-origin: left;
}

.footer-copyright {
  color: #888;
  font-size: 0.8rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
}

.footer-copyright-icon {
  font-size: 0.9rem;
  color: var(--link-blue);
}

.separator {
  color: var(--text-gray) !important;
}

@media (max-width: 767px) {
  .sidebar {
    width: 100% !important;
    padding: 0 20px;
    position: relative;
  }

  .mobile-toggle {
    background-color: white !important;
    border: 2px solid var(--abstract-background-gray) !important;
  }

  .messages .message.message.left .text-wrapper {
    padding-left: 20px !important;
  }

  .sidebar-expanded-content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .sidebar-title {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
    width: 100%;
    padding-top: 25px;
    font-size: 22px;
    text-align: right;
  }

  /* Mobile styles for enhanced list items */
  .dialogue-item,
  .bookmark-item {
    padding: 12px 15px !important;
  }

  .sidebar-list .MuiListItem-root {
    margin-bottom: 5px;
  }

  .dialogue-item:hover,
  .bookmark-item:hover {
    transform: translateX(2px);
  }

  .sidebar-default-content {
    margin: 0.75rem;
    padding: 1.25rem !important;
  }
}

@media screen and (max-width: 450px) {
  .custom-sidebar-button .button-label {
    display: block;
    width: 100%;
  }
}
