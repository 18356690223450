.skeleton {
  opacity: 0.7;
  animation: pulse 1.5s infinite;
  width: 40px;
  height: 25px;
}

@keyframes pulse {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.5;
  }
}
