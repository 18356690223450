.logo {
  width: 27px;
  margin-left: 5px;
}

.header-logo {
  width: 34px;
  margin-left: 12px;
}

.nav-link {
  font-family: 'dsText', serif;
}

.navbar-brand {
  font-family: 'dsText', serif;
  font-weight: 600;
}

select {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-family: 'dsText', serif;
  background-color: #fff;
  color: #728188;
  box-shadow: 0 0 5px #a0c3d2;
}

select:hover {
  border-color: #aaa;
  cursor: pointer;
}

select:focus {
  border-color: #728188;
  outline: none;
}

select:after {
  border-color: #728188;
  outline: none;
}

select option {
  padding: 20px;
}

.app-container {
  display: flex;
  /*height: calc(var(--vh, 1vh) * 100);*/
  overflow: hidden;
}

.chat-window-container {
  flex: 1;
}

@media (max-width: 767px) {
  .app-container {
    flex-direction: column;
  }
}

@media (min-width: 560px) and (max-width: 1223px) {
  .container {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}
