.cookie-consent-banner {
  position: fixed;
  top: 20px;
  left: 0;
  right: 0;
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 28px 75px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  z-index: 9999;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  animation: slide-in 2s ease forwards;
}

.cookie-consent-banner.slide-out {
  animation: slide-out 2s ease forwards;
}

@keyframes slide-in {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-out {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    opacity: 0;
  }
}

.cookie-consent-banner h3 {
  margin-top: 0;
  font-size: 1.2rem;
  font-weight: 600;
}

.cookie-consent-banner p {
  margin-bottom: 20px;
  font-size: 0.9rem;
  line-height: 1.5;
  color: #555;
}

.cookie-consent-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.cookie-consent-options label {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  font-size: 0.9rem;
  width: fit-content;
}

.cookie-consent-button {
  padding: 8px 16px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  border: none;
  transition: background-color 0.2s ease;
}

.btn-success {
  background-color: var(--button-background-blue);
  color: white;
}

.btn-success:hover {
  background-color: var(--button-background-hover-blue);
}

.btn-outline {
  background-color: transparent;
  color: var(--button-background-blue);
  border: 1px solid var(--button-background-blue);
}

.btn-outline:hover {
  background-color: var(--button-background-hover-blue);
  color: white;
}

.btn-grayscale {
  background-color: #f5f5f5;
  color: #555;
}

.btn-grayscale:hover {
  background-color: #e0e0e0;
}

.cookie-consent-options label:hover {
  cursor: pointer;
}

.cookie-consent-options input[type='checkbox'] {
  appearance: none;
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-right: 5px;
  position: relative;
  cursor: pointer;
}

.cookie-consent-options input[type='checkbox']:checked {
  background-color: var(--button-background-blue);
  border-color: var(--button-background-blue);
}

.cookie-consent-options input[type='checkbox']:checked::after {
  content: '';
  position: absolute;
  left: 5px;
  top: 2px;
  width: 5px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.cookie-consent-options input[type='checkbox']:disabled {
  background-color: #e0e0e0;
  border-color: #ccc;
  cursor: not-allowed;
}

.cookie-consent-options input[type='checkbox']:disabled:checked {
  background-color: #999;
  border-color: #999;
}

.cookie-consent-options label:has(input[type='checkbox']:disabled) {
  color: #777;
  cursor: not-allowed;
}
