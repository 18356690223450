:root {
  --orange: #ee7d11;
  --link-blue: #069;
  --button-background-blue: #025e8d;
  --hr-gray: #dcdcdc;
  --abstract-highlight-blue: #3365a0;
  --abstract-background-gray: #f1f1f1;
  --hover-background-gray: rgba(0, 0, 0, 0.04);
  --text-gray: #444;
  --button-background-hover-blue: #0377b5;
}

a {
  color: var(--link-blue) !important;
  text-decoration: none !important;
}

a:hover {
  text-decoration: underline !important;
}

@font-face {
  font-family: 'dsText';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/NeueHaasUnicaW1G-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'dsText Fallback';
  font-style: normal;
  font-weight: 400;
  src: local('Arial');
  ascent-override: 89.53%;
  descent-override: 22.73%;
  line-gap-override: 0%;
  size-adjust: 100.3%;
}

@font-face {
  font-family: 'dsText';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../fonts/NeueHaasUnicaW1G-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'dsText Fallback';
  font-style: normal;
  font-weight: 500;
  src: local('Arial');
  ascent-override: 87.68%;
  descent-override: 24.01%;
  line-gap-override: 0%;
  size-adjust: 102.88%;
}

@font-face {
  font-family: 'dsText';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/NeueHaasUnicaW1G-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'dsText Fallback';
  font-style: normal;
  font-weight: 600;
  src: local('Arial Bold');
  ascent-override: 92.97%;
  descent-override: 27.3%;
  line-gap-override: 0%;
  size-adjust: 97.45%;
}

@font-face {
  font-family: 'dsHeading';
  src: url('../fonts/TinkoffSans.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  text-rendering: optimizeLegibility;
  font-display: swap;
}

@font-face {
  font-family: 'dsHeading Fallback';
  font-style: normal;
  font-weight: 400;
  src: local('Arial');
  ascent-override: 103.36%;
  descent-override: 36%;
  line-gap-override: 0%;
  size-adjust: 88.33%;
}

@font-face {
  font-family: 'dsHeading';
  src: url('../fonts/TinkoffSans_Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  text-rendering: optimizeLegibility;
  font-display: swap;
}

@font-face {
  font-family: 'dsHeading Fallback';
  font-style: normal;
  font-weight: 500;
  src: local('Arial');
  ascent-override: 93.77%;
  descent-override: 32.66%;
  line-gap-override: 0%;
  size-adjust: 97.37%;
}

@font-face {
  font-family: 'dsHeading';

  src: url('../fonts/TinkoffSans_Bold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  text-rendering: optimizeLegibility;
  font-display: swap;
}

@font-face {
  font-family: 'dsHeading Fallback';
  font-style: normal;
  font-weight: 600;
  src: local('Arial');
  ascent-override: 89.11%;
  descent-override: 31.04%;
  line-gap-override: 0%;
  size-adjust: 102.46%;
}

/* For Chrome, Safari and Edge */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: transparent !important;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* For Firefox */
html {
  scrollbar-width: thin;
  scrollbar-color: #888 transparent;
}

.container {
  max-width: 950px !important;
  margin-top: 5px;
}

.slick-prev:before,
.slick-next:before {
  color: #006699 !important;
}

.chat-window {
  position: relative;
  width: calc(100% - 20px);
  max-width: 1250px !important;
  height: 89vh;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
}

.suggestion-window {
  position: relative;
  padding-top: 15px;
  width: calc(100% - 20px);
  max-width: 800px;
  top: 5px;
  left: 480px;
  transform: translateX(-50%);
  overflow: hidden;
}

.top-menu .title {
  text-align: center;
  color: #000000;
  font-size: 15px; /* 18px */
  font-family: 'dsText', serif;
}

@media (max-height: 850px) {
  .messages {
    height: 88vh !important;
  }
}
.messages {
  position: relative;
  list-style: none;
  padding: 10px 10px 20px 10px;
  margin: 0;
  /*height: 90vh;*/
  overflow-y: scroll;
  overflow-x: hidden;
}

.messages .message {
  clear: both;
  overflow: hidden;
  transition: all 0.5s linear;
  opacity: 0;
}

.messages .message.appeared {
  animation: messageAppear 0.5s ease-in-out forwards;
}

@keyframes messageAppear {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.messages .message.left .avatar {
  background-color: #ffffff00;
  float: left;
}

.messages .message.left .text-wrapper {
  background-color: #ffffff00;
  margin-left: 0px;
}

.messages .message.center .text-wrapper:hover {
  transform: scale(1.015);
}

.messages .message.left .text-wrapper::after,
.messages .message.left .text-wrapper::before {
  right: 100%;
  border-right-color: #ffffff;
}

.messages .message.left .text {
  color: #0e0e0e;
}

.messages .message.right {
  padding-top: 10px;
  padding-bottom: 15px;
}

.messages .message.right .avatar {
  background-color: #006699;
  float: right;
}

.messages .message.right .text-wrapper {
  background-color: var(--abstract-background-gray);
  margin-right: 0px;
  float: right;
  margin-right: 10px;
}

.messages .message.center .text-wrapper {
  background-color: #006699;
  margin-right: 0px;
  transition: transform 0.3s ease-in-out;
}

.messages .message.right .text-wrapper::after,
.messages .message.right .text-wrapper::before {
  left: 100%;
  border-left-color: #006699;
}

.messages .message.center .text-wrapper::after,
.messages .message.center .text-wrapper::before {
  left: 100%;
  border-left-color: #006699;
}

.messages .message.right .text {
  color: black;
}

.messages .message.center .text {
  color: #ffffff;
}

.messages .message.appeared {
  opacity: 1;
}

#cite-icon {
  height: 22px !important;
  width: 26px !important;
  margin-bottom: 5px;
}

#cite-icon:hover {
  cursor: pointer;
}

.cite-toggle {
  position: relative;
  cursor: pointer;
}

.cite-toggle:hover:after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: -30px;
  right: -50%;
  background-color: #333;
  color: #fff;
  font-size: 11px;
  padding: 2px 4px;
  border-radius: 4px;
  white-space: nowrap;
  z-index: 10;
}

#bookmark-icon {
  height: 28px !important;
  width: 32px !important;
  margin-bottom: 5px;
}

#bookmark-icon:hover {
  cursor: pointer;
}

#remove-bookmark-icon {
  height: 28px !important;
  width: 32px !important;
  margin-bottom: 5px;
}

#remove-bookmark-icon:hover {
  cursor: pointer;
}

.bookmark-toggle {
  position: relative;
  cursor: pointer;
}

.bookmark-toggle.modal-bookmark:hover:after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: -27px;
  right: -50%;
  background-color: #333;
  color: #fff;
  font-size: 11px;
  padding: 2px 4px;
  border-radius: 4px;
  white-space: nowrap;
  z-index: 10;
}

.bookmark-toggle.preview-bookmark:hover:after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: -30px;
  right: -50%;
  background-color: #333;
  color: #fff;
  font-size: 11px;
  padding: 2px 4px;
  border-radius: 4px;
  white-space: nowrap;
  z-index: 10;
}

.snippet {
  border-left: 4px solid var(--button-background-blue); /* Blue left border */
  padding: 10px; /* Padding around the code block */
  border-radius: 5px; /* Rounded corners */
  background-color: var(--abstract-background-gray);
}

.paper-preview-modal-container {
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  overflow: visible;
}

.paper-preview-modal-container:hover {
  transform: scale(1.02);
  overflow: visible;
}

.messages .message.message.left .text-wrapper {
  display: inline-block;
  padding-left: 10px;
  padding-right: 20px;
  padding-top: 15px;
  border-radius: 25px;
  /* width: calc(100% - 85px);*/
  position: relative;
}

.messages .message.message.right .text-wrapper {
  display: inline-block;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
  border-radius: 25px;
  /* width: calc(100% - 85px);*/
  position: relative;
}

.messages .message.message.center .text-wrapper {
  display: inline-block;
  padding-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 15px;
  border-radius: 25px;
  left: 50%;
  transform: translateX(-50%);
  position: relative;
}

.messages .message .text-wrapper::after,
.messages .message .text-wrapper:before {
  top: 18px;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  display: none;
}

.messages .message .text-wrapper::after {
  border-width: 13px;
  margin-top: 0px;
}

.messages .message .text-wrapper::before {
  border-width: 15px;
  margin-top: -2px;
}

.messages .message .text-wrapper .text {
  font-size: 16px;
  font-family: 'dsText', serif;
}

.top-wrapper {
  position: relative;
  width: 105%;
  background-color: #fff;
  padding: 10px 10px;
  margin-bottom: 5px;
  top: 0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
}

.bottom-wrapper {
  width: 100%;
  background-color: #ffffff !important;
  padding: 5px 5px 5px 5px;
  border-radius: 15px;
  border: 1px solid var(--button-background-blue);
  background: var(--abstract-background-gray) !important;
  display: block;
  position: relative;
}

.input-container-beginning-desktop {
  left: 50%;
  position: absolute;
  top: 49%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 90%;
}

.chat-footer.chat-bottom {
  position: relative;
  right: 20px;
  margin: auto;
  width: 96%;
  top: 14px;
}

.hello-message {
  text-align: center;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 1rem;
}

.bottom-wrapper .bw-yesno {
  display: inline-block;
  height: 50px;
  position: relative;
  padding: 0 10px;
}

.bottom-wrapper .message-input-wrapper {
  display: inline-block;
  height: 5vh;
  font-family: 'dsText', serif;
  width: 100%;
  position: relative;
  padding-left: 10px;
  background: var(--abstract-background-gray);
}

.bottom-wrapper .message-input-wrapper .message-input {
  border: none;
  height: 100%;
  box-sizing: border-box;
  width: 100%;
  position: absolute;
  outline-width: 0;
  color: gray;
}

.message-autocomplete {
  border: none;
  height: 100%;
  box-sizing: border-box;
  width: 100%;
  position: absolute;
  outline-width: 0;
  color: black;
  background: var(--abstract-background-gray);
}

.bottom-wrapper .bw-yesno .btn-message {
  width: calc(30%);
  height: 50px;
  display: inline-block;
  border-radius: 50px;
  background-color: #a0c3d2;
  border: 2px solid #a0c3d2;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s linear;
  text-align: center;
}

.bottom-wrapper .bw-yesno .btn-message:hover {
  color: #a0c3d2;
  background-color: #fff;
}

.bottom-wrapper .booking-message {
  width: calc(65% - 55px);
  height: 50px;
  display: inline-block;
  border-radius: 50px;
  background-color: #a0c3d2;
  border: 2px solid #a0c3d2;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s linear;
  text-align: center;
  float: center;
}
.bottom-wrapper .send-message {
  display: block;
  border-radius: 15px;
  color: var(--button-background-blue);
  border: var(--button-background-blue);
  position: relative;
  background: transparent;
}

.send-message:hover:after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: -24px;
  left: 45%;
  background-color: #333;
  color: #fff;
  font-size: 11px;
  padding: 2px 4px;
  border-radius: 4px;
  white-space: nowrap;
  z-index: 10000;
}

.bottom-wrapper.bw-chat {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}

.bottom-wrapper.bw-chat .message-input-wrapper {
  flex-grow: 1;
}

.bottom-wrapper.bw-chat .send-message {
  margin-left: 10px;
}

.bottom-wrapper .send-message:hover {
  cursor: pointer;
}

.bottom-wrapper .send-message .text,
.bottom-wrapper .booking-message .text,
.bottom-wrapper .yes-message .text,
.bottom-wrapper .no-message .text {
  font-size: 15px;
  display: inline-block;
  line-height: 48px;
}

.btn-suggestion:hover::before {
  content: attr(title);
  position: absolute;
  background-color: #000;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out; /* Adjust the transition timing */
}

.btn-suggestion:hover::before {
  opacity: 1;
  visibility: visible;
}

.message-template {
  display: none;
}

.btn-suggestion {
  margin: 2px;
  background-color: #006699 !important;
  border: none !important;
  width: 98%;
  height: 100%;
}

.btn-suggestion:hover {
  background-color: var(--button-background-hover-blue) !important;
}

* {
  box-sizing: border-box;
}

body {
  background-color: #edeff2;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 5px;
  border: 1px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

::-moz-scrollbar-button,
::-webkit-scrollbar-button {
  width: 0px;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.bold {
  font-weight: bold;
}

.ria-complete {
  padding: 10px 10px !important;
  font-size: 16px !important;
  background-color: var(--abstract-background-gray) !important;
}

.messages .message .text-wrapper .text h1,
.messages .message .text-wrapper .text h2,
.messages .message .text-wrapper .text h3,
.messages .message .text-wrapper .text h4,
.messages .message .text-wrapper .text h5 {
  font-size: calc(0.9rem + 0.6vw);
}

.messages .message .text-wrapper .text ul,
.messages .message .text-wrapper .text ol {
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.substance-property {
  padding-top: 1rem;
}

ol li p,
ul li p {
  margin-bottom: 0;
}

.messages .message .text-wrapper .text ul li > ul,
.messages .message .text-wrapper .text ul li > ol,
.messages .message .text-wrapper .text ol li > ul,
.messages .message .text-wrapper .text ol li > ol {
  padding-bottom: 0;
  padding-top: 0;
}

.substance-button {
  background-color: var(--button-background-blue);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 16px;
  transition: transform 0.3s ease-in-out;
}

.substance-button:hover {
  transform: scale(1.05);
}

.substance-buttons {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.paper-title {
  color: var(--link-blue);
  padding-bottom: 1rem;
  font-weight: bold;
  font-size: 1.05rem;
  text-decoration: underline;
}

.load-more-item {
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.load-more-item .load-more-button {
  display: block;
  margin: 0 auto;
}

.load-more-button {
  border-radius: 20px !important;
  transition: transform 0.3sease-in-out;
  padding: 8px 6px;
}

.load-more-button:hover {
  cursor: pointer;
  transform: scale(1.05) !important;
}

.load-more-text {
  padding-right: 5px;
  padding-left: 10px;
}

.bottom-wrapper .send-message.send-disabled {
  cursor: not-allowed;
  color: var(--hr-gray);
}

/* Page Layout */
.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.back-to-chat-link {
  display: block;
  text-align: left;
  font-family: 'dsText', serif;
  text-decoration: underline;
  max-width: 1150px;
  margin: 0 auto;
  padding: 1rem 1rem 0 1rem;
}

.back-to-chat-link a {
  text-decoration: underline var(--text-gray) !important;
}

.back-button {
  color: var(--text-gray);
}

.back-to-chat-link-link {
  display: inline-flex;
  align-items: center;
  transition: transform 0.2s ease-in-out;
}

.back-to-chat-link-link:hover {
  transform: scale(1.05);
}

.page-header {
  color: #000;
  padding: 1rem;
  text-align: center;
  font-family: 'dsHeading', sans-serif;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 32px;
}

.page-content {
  flex: 1;
  padding: 0 1rem;
  max-width: 1150px;
  margin: 0 auto;
  font-family: 'dsText', serif;
}

.page-content-headline {
  padding-left: 1rem;
  max-width: 1150px;
  margin: 0 auto;
  padding: 0 2rem 0 2rem;
  color: #000;
}

.page-content-container {
  padding: 0 2rem;
}

.page-footer {
  padding: 1rem;
  text-align: center;
  border-top: 1px solid var(--hr-gray);
  font-size: 0.9rem;
}

.page-footer a {
  color: var(--text-gray) !important;
}

.section {
  padding: 2rem 0;
}

/* Full-width section background with a centered inner container */
.section-inner {
  max-width: 1150px;
  margin: 0 auto;
  padding: 0 2rem;
}

/* Example: every second section gets a light background */
.section:nth-of-type(2n) {
  background-color: #f9f9f9;
}

.page-content h1,
.page-content h2,
.page-content h3 {
  font-family: 'dsHeading', sans-serif;
  color: var(--link-blue);
  margin-bottom: 1rem;
}

.link-blue {
  color: var(--link-blue);
}

.page-header-link {
  text-decoration: none;
  color: #000 !important;
}

.page-header-link:hover {
  text-decoration: none !important;
  color: #000 !important;
}

.italic {
  font-style: italic;
}

.consent-required-notice {
  margin-top: 15px;
  padding: 10px 15px;
  background-color: #f8f9fa;
  border-radius: 5px;
  text-align: center;
  border: 1px solid #dee2e6;
}

.consent-required-notice p {
  margin-bottom: 10px;
  font-size: 14px;
  color: #6c757d;
}

.cookie-settings-button {
  background-color: var(--button-background-blue);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.cookie-settings-button:hover {
  background-color: var(--button-background-hover-blue);
}

/* Smooth scroll adjustment for section anchors */
.section {
  scroll-margin-top: 70px;
}

/* Footer Styles */
.page-footer {
  background: linear-gradient(135deg, rgba(0, 102, 153, 0.05) 0%, rgba(0, 102, 153, 0.02) 100%);
  padding: 2rem 0;
  border-top: 1px solid rgba(0, 102, 153, 0.1);
  position: relative;
  overflow: hidden;
}

.page-footer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(90deg, transparent 0%, rgba(0, 102, 153, 0.2) 50%, transparent 100%);
}

.page-footer::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(90deg, transparent 0%, rgba(0, 102, 153, 0.2) 50%, transparent 100%);
}

.page-footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.footer-links {
  display: flex;
  gap: 2rem;
  align-items: center;
  flex-wrap: wrap;
}

.footer-links a {
  color: #666;
  text-decoration: none;
  font-size: 0.95rem;
  transition: all 0.3s ease;
  position: relative;
  padding: 0.5rem 0;
}

.footer-links a::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--link-blue);
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease;
}

.footer-links a:hover {
  color: var(--link-blue);
  text-decoration: none !important;
}

.footer-links a:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

.footer-copyright {
  color: #888;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.footer-copyright-icon {
  font-size: 1rem;
  color: var(--link-blue);
}

@media screen and (min-width: 768px) {
  .chat-window {
    height: 98vh;
    overflow-y: scroll;
  }

  .desktop-input-container-beginning {
    position: absolute;
    top: 49%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
  }

  .desktop-input-container {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
  }

  .messages {
    height: 90vh !important;
  }

  .chat-footer.chat-begin-centered {
    position: relative;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .messages {
    /*height: 90vh !important;*/
  }

  .chat-window {
    overflow: hidden;
    overflow-y: scroll;
  }

  .input-container {
    position: absolute;
    width: 100%;
    bottom: -5px;
    height: 80px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .chat-footer.chat-bottom {
    position: relative;
    right: 0;
    width: 96%;
  }

  .input-container > .chat-footer.chat-bottom {
    margin: 0 auto;
  }

  .messages:last-child {
    padding-bottom: 90px;
  }

  .message.right.appeared {
    width: 85%;
    margin-left: auto;
  }

  .send-message:hover:after {
    display: none;
  }

  .chat-footer.chat-begin-centered {
    position: absolute;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
  }

  .chat-footer.chat-bottom {
    top: 0;
  }

  .page-footer {
    padding: 1.5rem 0;
    margin-top: 3rem;
  }

  .page-footer-content {
    flex-direction: column;
    text-align: center;
    gap: 1.5rem;
  }

  .footer-links {
    justify-content: center;
  }
}
