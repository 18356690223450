/* Imprint Page Styles */
.imprint-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem 0;
}

.imprint-card {
  background: white;
  border-radius: 10px;
  padding: 1.5rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.imprint-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 102, 153, 0.15);
}

.imprint-subheader {
  color: var(--link-blue);
  font-size: 1.4rem;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 1rem;
  position: relative;
  padding-bottom: 0.5rem;
}

.imprint-subheader:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 2px;
  background: var(--link-blue);
  border-radius: 2px;
}

.imprint-subtitle {
  font-size: 1.1rem;
  color: #333;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  font-weight: 500;
}

.imprint-text {
  margin-top: 0;
  line-height: 1.5;
}

.imprint-link {
  color: var(--link-blue);
  text-decoration: none;
  font-weight: 500;
  position: relative;
  transition: all 0.3s ease;
}

.imprint-link:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: var(--link-blue);
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease;
}

.imprint-link:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.page-header-imprint {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

/* Responsive adjustments */
@media (min-width: 768px) {
  .imprint-container {
    padding: 1rem 2rem;
  }

  .imprint-card {
    padding: 2rem;
  }
}

@media (max-width: 767px) {
  .imprint-subheader {
    font-size: 1.2rem;
  }

  .imprint-subtitle {
    font-size: 1rem;
  }
}
