.substance-modal-overlay {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(3px);
  background: linear-gradient(135deg, rgba(222, 211, 255, 0.6) 0%, rgba(159, 208, 203, 0.6) 100%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.substance-modal-content {
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  max-width: 950px;
  width: 90%;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  position: relative;
}

.close-button {
  background: transparent;
  border: none;
  font-size: 1.4rem;
  cursor: pointer;
  position: absolute;
  top: 4px;
  right: 0;
  position: sticky;
  background: white;
  padding: 0 9px 0 10px;
  border-radius: 20px;
  border: 2px solid var(--abstract-background-gray) !important;
}

.substance-modal-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 400px;
}

.modal-title {
  width: 100%;
  margin-bottom: 2rem;
}

.modal-content-wrapper {
  display: flex;
  width: 100%;
}

.substance-modal-left {
  flex: 2;
  padding-right: 1rem;
}

.substance-modal-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}

.substance-modal-image-top,
.substance-modal-image-bottom {
  max-width: 300px;
  max-height: 220px;
  object-fit: cover;
  border-radius: 8px;
}

.substance-description {
  margin-bottom: 2rem;
}

.properties-grid {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.5rem 2rem;
  margin-top: 1rem;
}

.property-key {
  color: #666;
  text-align: right;
}

.property-value {
  color: #000;
}

.image-container {
  margin: auto;
}

@media screen and (max-width: 768px) {
  .substance-modal-content {
    max-width: 90%;
    padding: 1.5rem;
  }

  .substance-modal-image-top,
  .substance-modal-image-bottom {
    height: 200px;
    width: 100%;
    object-fit: contain;
  }

  .modal-content-wrapper {
    flex-direction: column;
    gap: 20px;
  }

  .substance-modal-left {
    padding-right: 0;
    width: 100%;
    word-break: break-all;
  }

  .substance-modal-right {
    margin-top: 2rem;
    width: 70%;
    gap: 20px;
    margin: auto;
  }

  .substance-modal-image-top,
  .substance-modal-image-bottom {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
}

@media screen and (max-width: 600px) {
  .substance-modal-content {
    max-width: 95%;
    max-height: 80vh;
    padding: 0 1rem 1rem 1rem;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .close-button {
    position: sticky;
    top: 0;
    right: 0;
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    background: white;
    z-index: 1;
    padding: 0 9px 0 10px;
    border-radius: 20px;
    margin-top: 15px;
    color: black;
  }

  .modal-content-wrapper {
    flex-direction: column;
  }

  .substance-modal-left {
    padding-right: 0;
    width: 100%;
    word-break: break-all;
  }

  .substance-modal-right {
    margin-top: 2rem;
    width: 100%;
  }

  .substance-modal-image-top,
  .substance-modal-image-bottom {
    width: 100%;
    height: 200px;
    object-fit: cover;
    margin: auto;
    display: block;
  }
}
