.page-content-headline-error {
  max-width: 1150px;
  margin: 0 auto;
  padding: 0 1rem 0 1rem;
  color: #000;
  text-align: center;
}

.page-content-headline-error {
  max-width: 1150px;
  margin: 0 auto;
  padding: 0 1rem;
  color: #000;
  text-align: center;
}

.error-main {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 200px);
  background: linear-gradient(135deg, rgba(0, 102, 153, 0.05) 0%, rgba(0, 102, 153, 0.02) 100%);
  padding: 2rem;
  position: relative;
  overflow: hidden;
}

.error-main::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%23006699' fill-opacity='0.05' fill-rule='evenodd'/%3E%3C/svg%3E");
  opacity: 0.7;
  z-index: 0;
}

.error-content {
  text-align: center;
  background: white;
  padding: 3rem;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 102, 153, 0.1);
  position: relative;
  z-index: 1;
  max-width: 600px;
  width: 100%;
  animation: fadeInUp 0.8s ease;
}

.page-content-headline-error {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  color: #000;
  text-align: center;
  background: linear-gradient(45deg, var(--link-blue), #4dabf7);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: fadeInDown 0.8s ease;
}

.error-description {
  margin: 2rem 0;
  font-size: 1.2rem;
  color: #555;
  line-height: 1.6;
  animation: fadeInUp 0.8s ease;
  animation-delay: 0.2s;
  animation-fill-mode: both;
}

.error-back-button {
  display: inline-block;
  padding: 1rem 2rem;
  background-color: white;
  color: var(--link-blue);
  border-radius: 30px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 102, 153, 0.1);
  font-size: 1.1rem;
  animation: fadeInUp 0.8s ease;
  animation-delay: 0.4s;
  animation-fill-mode: both;
}

.error-back-button:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 102, 153, 0.15);
  background-color: rgba(255, 255, 255, 0.95);
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .error-content {
    padding: 2rem;
  }

  .page-content-headline-error {
    font-size: 2.2rem;
  }

  .error-description {
    font-size: 1.1rem;
  }

  .error-back-button {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
  }
}
