.options-modal {
  z-index: 1500;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  min-width: 150px;
}

.option-button {
  width: 100%;
  padding: 16px 16px;
  border: none;
  background: none;
  text-align: left;
  cursor: pointer;
  font-size: 14px;
}

.option-button svg {
  fill: red;
}

.option-button:hover {
  background-color: var(--abstract-background-gray);
}
