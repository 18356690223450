.typing {
  position: relative;
  padding-bottom: 1rem;
  padding-left: 1rem;
}

.loading-message {
  position: relative;
  padding-left: 1rem;
  padding-bottom: 1rem;
  color: var(--hr-gray);
}

.typing span {
  content: '';
  animation: blink 2s infinite;
  animation-fill-mode: both;
  height: 12px;
  width: 12px;
  background: var(--button-background-blue);
  position: absolute;
  border-radius: 50%;
}

.typing span:nth-child(2) {
  animation-delay: 0.2s;
  margin-left: 18px;
}

.typing span:nth-child(3) {
  animation-delay: 0.4s;
  margin-left: 36px;
}

@keyframes blink {
  0% {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}
