.paper-modal-overlay {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(3px);
  background: linear-gradient(135deg, rgba(222, 211, 255, 0.6) 0%, rgba(159, 208, 203, 0.6) 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.paper-modal-content {
  background: #fff;
  padding: 0 2rem 0 2rem;
  border-radius: 8px;
  max-width: 950px;
  width: 90%;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  position: relative;
  max-height: 80vh;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.close-button {
  position: sticky;
  top: 0;
  right: 0;
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  background: red;
  padding: 0 10px;
  z-index: 1;
}

.close-button:hover {
  cursor: pointer;
  background-color: var(--abstract-background-gray);
}

.icon-button:hover {
  cursor: pointer;
}

.paper-modal-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 400px;
}

.modal-title {
  width: 100%;
  margin-bottom: 2rem;
}

.modal-content-wrapper {
  display: flex;
  width: 100%;
}

.paper-modal-left {
  flex: 2;
  padding-right: 1rem;
}

.paper-modal-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}

.paper-description {
  margin-bottom: 2rem;
  word-break: break-word;
}

.properties-grid {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.5rem 2rem;
  margin-top: 1rem;
}

@media screen and (max-width: 768px) {
  .paper-modal-content {
    max-width: 90%;
    padding: 0 1.5rem 0 1.5rem;
  }

  .modal-content-wrapper {
    flex-direction: column;
    gap: 20px;
  }

  .paper-modal-left {
    padding-right: 0;
    width: 100%;
    word-break: break-all;
  }

  .paper-modal-right {
    margin-top: 2rem;
    width: 70%;
    margin: auto;
  }
}

@media screen and (max-width: 600px) {
  .paper-modal-content {
    max-width: 95%;
    max-height: 80vh;
    padding: 0 1rem 0 1rem;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .modal-content-wrapper {
    flex-direction: column;
  }

  .paper-modal-left {
    padding-right: 0;
    width: 100%;
    word-break: break-all;
  }

  .paper-modal-right {
    margin-top: 2rem;
    width: 100%;
  }
}

.action-buttons-row {
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon-button {
  align-self: center;
}

.ask-paper-button {
  align-self: start;
  background: transparent;
}

.send-message:hover:after {
  content: attr(data-tooltip);
  position: absolute;
  top: -24px;
  bottom: 0px !important;
  left: 0%;
  background-color: #333;
  color: #fff;
  font-size: 11px;
  padding: 2px 4px;
  border-radius: 4px;
  white-space: nowrap;
  z-index: 10000;
  height: 20px;
  scale: none;
}

.send-message:hover {
  scale: none;
}

.paper-question-input {
  background: transparent;
  border: none;
}

.paper-question-input:focus {
  outline: none;
}

.action-buttons-left {
  display: flex;
  gap: 1rem;
}

.action-buttons-right {
  display: flex;
  gap: 1rem;
}

.paper-meta-info {
  margin-bottom: 1rem;
}

.citation-info {
  margin-bottom: 1rem;
}

.ask-paper-container {
  position: sticky;
  bottom: -1px;
  background: #ffffff;
  padding: 20px 20px 15px 20px;
  z-index: 2;
}

.paper-modal-messages {
  position: relative;
  list-style: none;
  padding: 0 10px 0 10px;
  margin: 0;
  /*height: 90vh;*/
  overflow-y: scroll;
  overflow-x: hidden;
}

.paper-modal-messages .message {
  clear: both;
  overflow: hidden;
  transition: all 0.5s linear;
  opacity: 0;
}

.paper-modal-messages .message.appeared {
  animation: messageAppear 0.5s ease-in-out forwards;
}

@keyframes messageAppear {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.paper-modal-messages .message.left .avatar {
  background-color: #ffffff00;
  float: left;
}

.paper-modal-messages .message.left .text-wrapper {
  background-color: #ffffff00;
  margin-left: 0px;
}

.paper-modal-messages .message.center .text-wrapper:hover {
  transform: scale(1.015);
}

.paper-modal-messages .message.left .text-wrapper::after,
.paper-modal-messages .message.left .text-wrapper::before {
  right: 100%;
  border-right-color: #ffffff;
}

.paper-modal-messages .message.left .text {
  color: #0e0e0e;
}

.paper-modal-messages .message.right {
  padding-top: 10px;
  padding-bottom: 15px;
}

.paper-modal-messages .message.right .avatar {
  background-color: #006699;
  float: right;
}

.paper-modal-messages .message.right .text-wrapper {
  background-color: var(--abstract-background-gray);
  margin-right: 0px;
  float: right;
  margin-right: 10px;
}

.paper-modal-messages .message.center .text-wrapper {
  background-color: #006699;
  margin-right: 0px;
  transition: transform 0.3s ease-in-out;
}

.paper-modal-messages .message.right .text-wrapper::after,
.paper-modal-messages .message.right .text-wrapper::before {
  left: 100%;
  border-left-color: #006699;
}

.paper-modal-messages .message.center .text-wrapper::after,
.paper-modal-messages .message.center .text-wrapper::before {
  left: 100%;
  border-left-color: #006699;
}

.paper-modal-messages .message.right .text {
  color: black;
}

.paper-modal-messages .message.center .text {
  color: #ffffff;
}

.paper-modal-messages .message.appeared {
  opacity: 1;
}

.paper-modal-message .message.message.right .text-wrapper {
  display: inline-block;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
  border-radius: 25px;
  /* width: calc(100% - 85px); */
  position: relative;
}

.paper-modal-messages .message.message.left .text-wrapper {
  display: inline-block;
  padding-left: 10px;
  padding-right: 20px;
  padding-top: 15px;
  border-radius: 25px;
  /* width: calc(100% - 85px);*/
  position: relative;
}

.paper-modal-messages .message.message.right .text-wrapper {
  display: inline-block;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
  border-radius: 25px;
  /* width: calc(100% - 85px);*/
  position: relative;
}

.paper-modal-messages .message.message.center .text-wrapper {
  display: inline-block;
  padding-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 15px;
  border-radius: 25px;
  left: 50%;
  transform: translateX(-50%);
  position: relative;
}

.paper-modal-messages .message .text-wrapper::after,
.paper-modal-messages .message .text-wrapper:before {
  top: 18px;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  display: none;
}

.paper-modal-messages .message .text-wrapper::after {
  border-width: 13px;
  margin-top: 0px;
}

.paper-modal-messages .message .text-wrapper::before {
  border-width: 15px;
  margin-top: -2px;
}

.paper-modal-messages .message .text-wrapper .text {
  font-size: 16px;
  font-family: 'dsText', serif;
}

@media (max-height: 850px) {
  .paper-modal-messages {
    height: 88vh !important;
  }
}
.paper-modal-messages {
  position: relative;
  list-style: none;
  padding: 10px 10px 0 10px;
  margin: 0;
  /*height: 90vh;*/
  overflow-y: scroll;
  overflow-x: hidden;
}

.paper-modal-messages .message {
  clear: both;
  overflow: hidden;
  transition: all 0.5s linear;
  opacity: 0;
}

.paper-modal-messages .message.appeared {
  animation: messageAppear 0.5s ease-in-out forwards;
}

.paper-modal-messages h1 {
  font-size: 1.45rem;
  padding-top: 10px;
}

.paper-modal-messages h2 {
  font-size: 1.35rem;
  padding-top: 10px;
}

.paper-modal-messages h3 {
  font-size: 1.25rem;
  padding-top: 10px;
}

.ask-paper-input-wrapper {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #ffffff !important;
  padding: 5px 5px 5px 5px;
  border-radius: 15px;
  border: 1px solid var(--button-background-blue);
  background: var(--abstract-background-gray) !important;
  display: block;
  position: relative;
}

.ask-paper-button.send-message.send-disabled {
  cursor: not-allowed;
  color: var(--hr-gray);
}

.ask-paper-button.send-message {
  display: block;
  border-radius: 15px;
  color: var(--button-background-blue);
  border: var(--button-background-blue);
  position: relative;
  background: transparent;
}

.substance-button:hover {
  scale: none;
}

.beta-notice {
  color: var(--text-gray);
  font-size: 0.85rem;
  position: absolute;
  top: -20px;
  right: 5px;
  font-style: italic;
}

.paper-question-input::placeholder {
  font-style: italic;
}

/* For better cross-browser compatibility */
.paper-question-input::-webkit-input-placeholder {
  font-style: italic;
}

.paper-question-input::-moz-placeholder {
  font-style: italic;
}

.paper-question-input:-ms-input-placeholder {
  font-style: italic;
}

.icon-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.clear-context-button {
  background: transparent;
  border: none !important;
  font-size: 0.8rem;
  cursor: pointer;
  position: absolute;
  right: 0;
  position: sticky;
  background: white;
  display: block;
  padding: 10px 10px 10px 10px;
  border-radius: 20px;
}

.clear-context-button:hover {
  cursor: pointer;
  background-color: var(--abstract-background-gray);
}

.clear-context-button.send-disabled {
  cursor: not-allowed;
  color: var(--hr-gray);
}

.clear-context-button:hover:after {
  content: attr(data-tooltip);
  position: absolute;
  top: -30px;
  bottom: 0px !important;
  left: -21%;
  background-color: #333;
  color: #fff;
  font-size: 11px;
  padding: 2px 4px;
  border-radius: 4px;
  white-space: nowrap;
  z-index: 10000;
  height: 20px;
  scale: none;
}

.paper-icon {
  font-size: 28px;
  color: var(--button-background-blue);
}

.paper-icon.disabled {
  cursor: not-allowed;
  color: var(--hr-gray);
}

.clear-context-button-text {
  display: block;
}
